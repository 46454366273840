import axios from "axios";

export default {
  data: () => ({
    search_text: "",
    suggests: [],
    selected_suggests: "",
    combo_loading: false,
    remove_dialog: false,
    count: 0,
    // add form
    question: "",
    currect: "",
    item1: "",
    item2: "",
    item3: "",
    item4: "",

    // edit form
    select_question: "",
    select_currect: "",
    select_item1: "",
    select_item2: "",
    select_item3: "",
    select_item4: "",
  }),

  mounted() {
    this.get_count()
  },

  methods: {
    async get_count() {
      await axios({
        method: "GET",
        url: "/admin/questions/count",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.count = res.data.data.count;
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          // this.$store.commit('progress_status', false)
          this.combo_loading = false;
        });
    },

    async get_suggests() {
      if (this.search_text && this.search_text.length > 1) {
        this.suggests = [];
        // console.log(this.search_text);
        // this.$store.commit('progress_status', true)
        this.combo_loading = true;

        await axios({
          method: "GET",
          url: "/admin/questions/search",
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
          },
          params: {
            q: this.search_text,
          },
        })
          .then((res) => {
            console.log(res.data.data.questions);
            this.suggests = res.data.data.questions;
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("snackbar", {
              color: "red",
              text: err.response.data.errors,
            });
          })
          .finally(() => {
            // this.$store.commit('progress_status', false)
            this.combo_loading = false;
          });
      } else {
        this.suggests = [];
      }
    },

    // when select from comboBox
    search() {
      if (this.search_text.length < 2) {
        this.suggests = [];
      } else {
        console.log(this.selected_suggests);
        let answers = this.selected_suggests.answers;
        this.select_question = this.selected_suggests.title;

        this.select_item1 = answers[0].title;
        this.select_item2 = answers[1].title;
        this.select_item3 = answers[2].title;
        this.select_item4 = answers[3].title;

        for (let i = 0; i < answers.length; i++) {
          if (answers[i].isCorrect === true) {
            this.select_currect = "select_item" + [i + 1];
          }
        }
      }
    },

    async add() {
      // create answer array
      const answers = [
        {
          title: this.item1,
          isCorrect: this.currect === "item1",
        },
        {
          title: this.item2,
          isCorrect: this.currect === "item2",
        },
        {
          title: this.item3,
          isCorrect: this.currect === "item3",
        },
        {
          title: this.item4,
          isCorrect: this.currect === "item4",
        },
      ];

      if (this.currect === "") {
        this.$store.commit("snackbar", {
          color: "red",
          text: "select currect answer",
        });
      } else {
        this.$store.commit("progress_status", true);
        await axios({
          method: "POST",
          url: "/admin/questions",
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
          },
          data: {
            title: this.question,
            answers,
          },
        })
          .then((res) => {
            console.log(res.data.data);
            this.$store.commit("snackbar", {
              color: "green",
              text: "Question added successfully",
            });

            // clear form
            this.currect = "";
            this.question = "";
            this.item1 = "";
            this.item2 = "";
            this.item3 = "";
            this.item4 = "";
            //   this.suggests = res.data.data.questions
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("snackbar", {
              color: "red",
              text: err.response.data.errors,
            });
          })
          .finally(() => {
            this.$store.commit("progress_status", false);
          });
      }
    },

    async edit() {
      // create answer array
      const answers = [
        {
          title: this.select_item1,
          isCorrect: this.select_currect === "select_item1",
        },
        {
          title: this.select_item2,
          isCorrect: this.select_currect === "select_item2",
        },
        {
          title: this.select_item3,
          isCorrect: this.select_currect === "select_item3",
        },
        {
          title: this.select_item4,
          isCorrect: this.select_currect === "select_item4",
        },
      ];
      console.log(answers);

      this.$store.commit("progress_status", true);
      await axios({
        method: "PUT",
        url: "/admin/questions/" + this.selected_suggests._id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        data: {
          title: this.select_question,
          answers,
        },
      })
        .then((res) => {
          console.log(res.data.data);
          this.$store.commit("snackbar", {
            color: "green",
            text: "Question edited successfully",
          });

          // clear form
          this.question = "";
          this.item1 = "";
          this.item2 = "";
          this.item3 = "";
          this.item4 = "";
          //   this.suggests = res.data.data.questions
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    async remove() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "DELETE",
        url: "/admin/questions/" + this.selected_suggests._id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log(res.data.data);
          this.selected_suggests = "";
          this.suggests = [];
          this.remove_dialog = false;
          this.$store.commit("snackbar", {
            color: "green",
            text: "Question deleted successfully",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },
  },
};
