import axios from "axios";

export default {
  data: () => ({
    role_type: "",
    role_types: [
      { title: "Main Admin", value: 1 },
      { title: "Manager", value: 2 },
      { title: "Auditor", value: 3 },
    ],
    access: [],
    member_list: [],
    edit_mode: false,
    admin_edit_mode: false,
    admin_dialog: false,

    admins: [],
    filtered_admins: [],
    admin: {},

    name: "",
    email: "",
    phone: "",
    password: "",
    location: "",

    e_role_type: "",
    e_name: "",
    e_email: "",
    e_phone: "",
    e_password: "",
    e_location: "",
    e_access: [],

    reputations: {},
    f1: "",
    f2: "",
    f3: "",
    f4: "",
    f5: "",

    sliders: [],
    link: "",
    groupOpt: ["business", "customer", "venue"],
    group: [],
  }),

  mounted() {
    this.get_reputation();
    this.get_admins();
    this.get_announcements();
  },

  methods: {
    async get_reputation() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/admin/settings/reputations",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        //   data: {
        //     email: this.email,
        //     password: this.password,
        //   }
      })
        .then((res) => {
          console.log(res.data);
          this.reputations = res.data.data.setting.reputation;
          this.f1 = this.reputations.lt1000 * 100;
          this.f2 = this.reputations.lt5000 * 100;
          this.f3 = this.reputations.lt10000 * 100;
          this.f4 = this.reputations.lt50000 * 100;
          this.f5 = this.reputations.gte50000 * 100;
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    async edit_reputation() {
      if (!this.f1 || !this.f2 || !this.f3 || !this.f4 || !this.f5) {
        this.$store.commit("snackbar", {
          color: "red",
          text: "Please fill out all fields.",
        });
      } else {
        this.$store.commit("progress_status", true);
        await axios({
          method: "PUT",
          url: "/admin/settings/reputations",
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
          },
          data: {
            lt1000: this.f1,
            lt5000: this.f2,
            lt10000: this.f3,
            lt50000: this.f4,
            gte50000: this.f5,
          },
        })
          .then((res) => {
            console.log(res.data);
            this.reputations = res.data.data.setting.reputation;
            this.f1 = this.reputations.lt1000 * 100;
            this.f2 = this.reputations.lt5000 * 100;
            this.f3 = this.reputations.lt10000 * 100;
            this.f4 = this.reputations.lt50000 * 100;
            this.f5 = this.reputations.gte50000 * 100;
            this.edit_mode = false;
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("snackbar", {
              color: "red",
              text: err.response.data.errors,
            });
          })
          .finally(() => {
            this.$store.commit("progress_status", false);
          });
      }
    },

    async get_admins() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/admin/settings/admins",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log(res.data);
          this.admins = res.data.data.admins;
          this.filtered_admins = res.data.data.admins;
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    async change_activity(admin) {
      this.$store.commit("progress_status", true);

      await axios({
        method: "PATCH",
        url: "/admin/settings/admins/" + admin._id + "/active",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        params: {
          id: admin.id,
        },
        data: {
          active: !admin.isActive,
        },
      })
        .then((res) => {
          console.log(res.data);
          if (!admin.isActive === true) {
            this.$store.commit("snackbar", {
              color: "green",
              text: "Admin user is active now.",
            });
          } else {
            this.$store.commit("snackbar", {
              color: "green",
              text: "Admin user is inactive now.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    async edit_admin() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "PUT",
        url: "/admin/settings/admins/" + this.admin._id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        data: {
          role: this.e_role_type,
          name: this.e_name,
          email: this.e_email,
          phone: this.e_phone,
          password: this.e_password || undefined,
          location: this.e_location,
          permissions: this.e_access,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.empty();
          this.get_admins();
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    validation(type) {
      if (type === "add") {
        if (
          !this.role_type ||
          !this.name ||
          !this.email ||
          !this.phone ||
          !this.password ||
          !this.location ||
          this.access.length === 0
        ) {
          this.$store.commit("snackbar", {
            color: "red",
            text: "Please fill out the form correctly.",
          });
        } else if (!this.email.includes(".") || !this.email.includes("@")) {
          this.$store.commit("snackbar", {
            color: "red",
            text: "Email address is not valid.",
          });
        } else if (this.phone.length !== 10) {
          this.$store.commit("snackbar", {
            color: "red",
            text: "Phone number is not valid.",
          });
        } else if (this.password.length < 5) {
          this.$store.commit("snackbar", {
            color: "red",
            text: "Password must be more than 4 characters.",
          });
        } else {
          this.add_admin();
        }
      } else {
        if (
          !this.e_role_type ||
          !this.e_name ||
          !this.e_email ||
          !this.e_phone ||
          !this.e_location ||
          this.e_access.length === 0
        ) {
          this.$store.commit("snackbar", {
            color: "red",
            text: "Please fill out the form correctly.",
          });
        } else if (!this.e_email.includes(".") || !this.e_email.includes("@")) {
          this.$store.commit("snackbar", {
            color: "red",
            text: "Email address is not valid.",
          });
        } else if (this.e_phone.length !== 10) {
          this.$store.commit("snackbar", {
            color: "red",
            text: "Phone number is not valid.",
          });
        } else {
          this.edit_admin();
        }
      }
    },

    async add_admin() {
      console.log({
        role: this.role_type,
        name: this.name,
        email: this.email,
        phone: this.phone,
        password: this.password,
        location: this.location,
        permissions: this.access,
      });

      this.$store.commit("progress_status", true);
      await axios({
        method: "POST",
        url: "/admin/settings/admins",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        data: {
          role: this.role_type,
          name: this.name,
          email: this.email,
          phone: this.phone,
          password: this.password,
          location: this.location,
          permissions: this.access,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.empty();
          this.get_admins();
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    filtering() {
      let new_list = [];
      this.admins.filter((item) => {
        for (let i = 0; i < this.member_list.length; i++) {
          if (this.member_list[i] == item.role) {
            new_list.push(item);
          }
        }
      });
      this.filtered_admins = new_list;

      if (this.member_list.length === 0) {
        this.filtered_admins = this.admins;
      }
    },

    empty() {
      this.role_type;
      this.role_type = "";
      this.name = "";
      this.email = "";
      this.phone = "";
      this.password = "";
      this.location = "";
      this.access = [];
    },

    open_info(info) {
      this.admin_dialog = true;
      this.admin = info;
      console.log(this.admin);

      this.e_role_type = this.admin.role;
      this.e_name = this.admin.name;
      this.e_email = this.admin.email;
      this.e_phone = this.admin.phone;
      // this.e_password = this.admin.password
      this.e_location = this.admin.location;
      this.e_access = this.admin.permissions;
    },

    change_mode() {
      this.edit_mode = !this.edit_mode;
    },

    // SLIDERS

    async get_announcements() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/admin/settings/announcements",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log(res.data);

          this.sliders = res.data.data.announcements;
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    async handleImageUpload(event, num) {
      const [file] = document.getElementById("logo").files;
      if (file) {
        document.getElementById("img").src = URL.createObjectURL(file);
      }
    },

    async send_image() {
      const formData = new FormData();
      formData.append("image", document.getElementById("logo").files[0]);
      if (!document.getElementById("logo").files[0]) {
        this.$store.commit("snackbar", {
          color: "red",
          text: "Image are required",
        });
      } else if (this.link && !this.link.includes(".")) {
        this.$store.commit("snackbar", {
          color: "red",
          text: "Link is invalid",
        });
      } else {
        this.$store.commit("progress_status", true);
        await axios({
          method: "POST",
          url: "/api/images",
          headers: {
            Authorization: "Bearer" + " " + localStorage.getItem("token"),
          },
          data: formData,
        })
          .then((res) => {
            console.log(res.data);
            this.logo = res.data.data.filename;
            // localStorage.setItem('logo', this.logo)
            this.add_image(res.data.data.filename);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.$store.commit("progress_status", false);
          });
      }
    },

    async add_image(img) {
      this.$store.commit("progress_status", true);
      await axios({
        method: "POST",
        url: "/admin/settings/announcements",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        data: {
          imageUrl: img,
          link: this.link,
          bannerType: this.group,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.get_announcements();
          document.getElementById("logo").files = null;
          document.getElementById("img").src = "";
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    async remove_image(id) {
      this.$store.commit("progress_status", true);
      await axios({
        method: "DELETE",
        url: "/admin/settings/announcements/" + id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log(res.data);
          this.$store.commit("snackbar", {
            color: "green",
            text: "Removed successfully",
          });

          this.get_announcements();
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar", {
            color: "red",
            text: err.response.data.errors,
          });
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },
  },
};
