import axios from "axios";

export default {

  data: () => ({
    new_users : [],
    selected :{},

    tiktok :0,
    instagram :0,
    use_tiktok :false,
    broser_link:'',

  }),

  mounted() {
    this.get_new_users()
  },

  methods: {
    async get_new_users() {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'GET',
        url: '/admin/customers/new',
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
      })
        .then((res) => {
          console.log(res.data.data);
          this.new_users = res.data.data.customers;
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },

    async update_user(action) {
      this.$store.commit('progress_status', true)
      await axios({
        method: 'PATCH',
        url: '/admin/customers/new/'+this.selected._id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token")
        },
        data:{
          action, // verify or reject
          instagramFollowersCount: this.instagram, // optional
          tiktokFollowersCount: this.tiktok, // optional
          canCustomerUseTiktok: !this.use_tiktok // optional
        }
      })
        .then((res) => {
          console.log(res.data.data);
          this.selected = {}
           this.get_new_users()
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })
    },
  }
};