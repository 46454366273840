import { render, staticRenderFns } from "./blocked_users.vue?vue&type=template&id=1d00a3f3"
import script from "./blocked_users?vue&type=script&lang=js&external"
export * from "./blocked_users?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports