import axios from "axios";

export default {
  
    data: () => ({
        info_dialog : false,
        venue_id : '',
        payments : [],
        selected : {},
    }),
  
    mounted() {
        this.venue_id = localStorage.getItem('venueId')
        this.get_deposit()
    },

    methods: {
        async get_deposit() {
            this.$store.commit('progress_status', true)

            await axios({
                method: 'GET',
                url: '/admin/venues/' + this.venue_id + '/payment-history',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
            })
                .then((res) => {
                    console.log(res.data.data);
                    this.payments = res.data.data.paymentsHistory
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)

                })
        },
        open_info(info){
            this.info_dialog = true
            this.selected = info
        },

        fixed(num) {
            return (+num).toFixed(2)
        },
    }
  };