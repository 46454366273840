import { render, staticRenderFns } from "./rejected_venues.vue?vue&type=template&id=48536ca8"
import script from "./rejected_venues?vue&type=script&lang=js&external"
export * from "./rejected_venues?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports