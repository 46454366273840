import axios from "axios";

export default {

    data: () => ({
        search_type: 'both',
        search_types: [{ title: 'Current', value: 'current' }, { title: 'Previous', value: 'prev' }, { title: 'Both', value: 'both' }],
        user_id: '',
        activities: [],
        camp_dialog: false,
        selected: {},
        link: '',
    }),

    mounted() {
        this.user_id = localStorage.getItem('userId')
         if (!this.user_id) {
            this.$router.push('/find_user')
            this.$store.commit('snackbar', { color: 'blue', text: 'Please select a user.' })
        }
        else{
            this.get_activities()
        }
    },

    methods: {
        async get_activities() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/customers/' + this.user_id + '/activites',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                params: {
                    condition: this.search_type
                },
            })
                .then((res) => {
                    console.log(res.data);
                    this.activities = res.data.activities

                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        open_dialog(info) {
            this.camp_dialog = true
            this.selected = info
        },

        open_link(link) {
            window.open('http://' + link, '_blank')
        }
    }
};