import axios from "axios";

export default {

    data: () => ({
        blocked_users: [],
        selected :{},
    }),

    mounted() {
        this.get_blocked_users()
    },

    methods: {
        async get_blocked_users() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/venues/suspends',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
            })
                .then((res) => {
                    console.log(res.data.data);
                    this.blocked_users = res.data.data.suspends
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })

                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async suspend(type) {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'PATCH',
                url: '/admin/venues/' + this.selected.user._id + '/suspend',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                data: {
                    action: type
                }
            })
                .then((res) => {
                    console.log(res.data);
                    if (type === 'unblock') {
                        this.$store.commit('snackbar', { color: 'green', text: 'Venue unblocked successfully.' })
                    }
                    else {
                        this.$store.commit('snackbar', { color: 'green', text: 'Venue blocked successfully.' })
                    }
                    this.get_blocked_users()
                    this.selected = {}
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },


           open_link(link) {
            window.open(link, '_blank')
        },
    }
};