import axios from "axios";

export default {

    data: () => ({

        payments_dialog: false,
        vouchers_dialog: false,
        selected: {},

        search_type: 'payment',
        search_types: [{ title: 'Payments', value: 'payment' }, { title: 'Vouchers', value: 'voucher' }, { title: 'Both', value: 'both' }],
        user_id: '',
        v_wallets: [],
        p_wallets: [],
    }),

    mounted() {
        this.user_id = localStorage.getItem('userId')
        if (!this.user_id) {
            this.$router.push('/find_user')
            this.$store.commit('snackbar', { color: 'blue', text: 'Please select a user.' })
        }
        else {
            this.get_wallets()
        }

    },

    methods: {
        async get_wallets() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/customers/' + this.user_id + '/wallets',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                params: {
                    condition: this.search_type
                },
            })
                .then((res) => {
                    console.log(res.data);
                    this.v_wallets = res.data.data.vouchers;
                    this.p_wallets = res.data.data.payments;
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        open_payment_dialog(data) {
            this.payments_dialog = true
            this.selected = data
        },

        open_voucher_dialog(data) {
            this.vouchers_dialog = true
            this.selected = data
        },
        
        cut(num) {
            let x = +num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
            return x.toFixed(2)
        }
    }
};