import axios from "axios";

export default {

  data: () => ({
    email: '',
    password: '',
  }),

  mounted() {
    localStorage.clear()

  },

  methods: {
    async login() {

      this.$store.commit('progress_status', true)
      await axios({
        method: 'POST',
        url: '/admin/signin',
        data: {
          email: this.email,
          password: this.password,
        }
      })
        .then((res) => {
          console.log(res.data);
          localStorage.setItem('token', res.data.data.token)
          localStorage.setItem('permissions', JSON.stringify(res.data.data.admin.permissions) )
          localStorage.setItem('role', res.data.data.admin.role)
          this.$router.push('/stats')
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
        })
        .finally(() => {
          this.$store.commit('progress_status', false)
        })

    },
  }
};